import { NavLink } from "react-router-dom";
import { Section } from '../Section';
import { Footer as icons } from '../../assets/images'
import { useTranslation } from "react-i18next";
import './index.scss'

export function Footer() {
    const { t } = useTranslation()
    return (
        <Section containerClass='section-footer-bg' className='section-footer'>
            <footer>
                <div className="top">
                    <div className="left">
                        <a className="logo-box">
                            <img className="logo" src={icons.Logo} />
                            <span className="logo-text">BiT Global</span>
                        </a>
                        <div className="text1">{t('footer.h1')}</div>
                        <div className="text2">{(t('footer.h2', { returnObjects: true }) as string[]).map(text => {
                            return (
                                <span key={text}>{text}</span>
                            )
                        })}</div>
                        <div className="text3">{t('footer.p')}</div>
                        <a className="text4" href={`mailto:${t('footer.email')}`}>
                            <img src={icons.Email} />
                            <span>{t('footer.email')}</span>
                        </a>
                    </div>
                    <div className="right">
                        {(t('footer.menu', { returnObjects: true }) as { title: string, href?: string, children: { title: string, href: string }[] }[]).map(el => {
                            return (
                                <div className="nav-item" key={el.title}>
                                    {el.href ? (
                                        <NavLink onClick={() => window.scrollTo({ top: 0 })} className={'title'} to={el.href}>{el.title}</NavLink>
                                    ) : (
                                        <div className="title">{el.title}</div>
                                    )}
                                    {el.children && (
                                        <div className="submenu">
                                            {el.children?.map(item => {
                                                return (
                                                    <NavLink key={item.title} onClick={() => window.scrollTo({ top: 0 })} className={'submenu-item'} to={item.href}>{item.title}</NavLink>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="divider"></div>
                <div className="bottom">
                    <span>{t('footer.copyright')}</span>
                    <div>
                        <a onClick={() => window.scrollTo({ top: 0 })} className="policy-link" href="/terms-of-use" target="_blank">{t('footer.terms')}</a>
                        <a onClick={() => window.scrollTo({ top: 0 })} className="policy-link" href="/privacy-policy" target="_blank">{t('footer.policy')}</a>
                        <a onClick={() => window.scrollTo({ top: 0 })} className="policy-link" href="/fork-policy" target="_blank">{t('footer.forkPolicy')}</a>
                    </div>
                </div>
                <img className="to-top" src={icons.ToTop} onClick={() => window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                })} />
            </footer>
        </Section>
    )
}