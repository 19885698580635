import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  useLocation
} from "react-router-dom";
import { I18nextProvider } from 'react-i18next';
import i18n from './assets/locales';
import './assets/styles/global.scss'
import { Disclaimer, Loading } from './components';
import Home from './pages/Home'

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const NotFound = React.lazy(() => import("./pages/NotFound"))
const AboutUs = React.lazy(() => import("./pages/AboutUs"))
const Coins = React.lazy(() => import("./pages/Coins"))
const Career = React.lazy(() => import("./pages/Career"))
const Custody = React.lazy(() => import("./pages/Custody"))
const Newsroom = React.lazy(() => import("./pages/Newsroom"))
const JoinUs = React.lazy(() => import("./pages/JoinUs"))
const TermsOfUse = React.lazy(() => import("./pages/Protocol/TermsOfUse"))
const PrivacyPolicy = React.lazy(() => import("./pages/Protocol/PrivacyPolicy"))
const ForkPolicy = React.lazy(() => import("./pages/Protocol/ForkPolicy"))


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/coins",
    element: <Coins />,
  },
  {
    path: "/career",
    element: <Career />,
  },
  {
    path: "/custody",
    element: <Custody />
  },
  // {
  //   path: "/news-room",
  //   element: <Newsroom />,
  // },
  {
    path: "/join-us",
    element: <JoinUs />,
  },
  {
    path: "/terms-of-use",
    element: <TermsOfUse />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/fork-policy",
    element: <ForkPolicy />,
  },
  {
    path: "/loading",
    element: <Loading />
  },
  {
    path: "*",
    element: <NotFound />
  }
]);


const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <I18nextProvider i18n={i18n} defaultNS={'translation'}>
        {/* @ts-ignore */}
        <RouterProvider router={router} >
          <ScrollToTop />
        </RouterProvider>
        {/* <Disclaimer /> */}
      </I18nextProvider>
    </Suspense>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
